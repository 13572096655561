import { Dispatch } from 'redux';
import { sendAddToCartEvent, sendAddingSingleProductToCart, sendStatusCart } from 'src/utils/marketing/enhancedEcommerce';
import { Product } from '../../../apiTypes';
import { openProductNotification } from '../../../notification';
import { RootState } from '../../../reducers';
import { getSendingToCartItems } from '../../general/helpers/getSendingToCartItems';
import { ServerCartItem } from '../types';
import updateServerCartItemsInState from '../updateServerCartItemsInState';
import updateCartItemsOnServerWithDebounce from './updateCartItemsOnServerWithDebounce';

export const placeProductToServerCart = (
  ean: string,
  amount: number,
  analyticsData: {
    place: string;
    product: Product;
  },
) => (
  dispatch: Dispatch,
  getState: () => RootState,
) => {
  const { product, place } = analyticsData;
  const state = getState();
  const cartItemsUpdateData = getSendingToCartItems([{ ean, amount }], 'add');
  const cartItems = state.serverCart.data.items;
  const currency = state.initialAppConfig.currency;
  const cartItemIndex = cartItems.findIndex(i => i.ean === ean);
  const updatedCartItemIsInCart = cartItemIndex !== -1;
  const newCartItems = [...cartItems];

  if(updatedCartItemIsInCart) {
    const curCartItem = newCartItems[cartItemIndex];

    newCartItems[cartItemIndex] = {
      ...curCartItem,
      amount: curCartItem.amount + amount,
    };
  } else {
    const cartItem: ServerCartItem = {
      ean,
      amount: amount,
      unit: product.unit,
      bundle: product.bundle,
      comments: '', // todo: take comments from a correct place of server cart state
      currency: product.currency,
      price: product.price,
    };

    newCartItems.push(cartItem);
    dispatch(openProductNotification({...product, amount}));
  }

  dispatch(updateServerCartItemsInState(newCartItems));
  dispatch(updateCartItemsOnServerWithDebounce(cartItemsUpdateData));

  // ======== Analytics logic ========== start
  sendAddingSingleProductToCart(product, { amount, place, currency });
  sendAddToCartEvent({
    products: [{ ...product, amount }],
    currency,
    place,
  });
  sendStatusCart(newCartItems);
  // ======== Analytics logic ========== end
};
