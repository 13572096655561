import { Dispatch } from 'redux';
import { sendAddToCartGoal } from '../../../../utils/marketing/enhancedEcommerce';
import { Product } from '../../../apiTypes';
import { openTextNotification } from '../../../notification';
import { RootState } from '../../../reducers';
import changeCartItemAmount from './changeCartItemAmount';
import removeCartItem from './removeCartItem';

const updateAmount = (
  newAmount: number, place: string, product: Product,
) => (dispatch: Dispatch, getState: () => RootState) => {
  if (newAmount === 0) {
    dispatch(openTextNotification('product_removed'));
    dispatch(removeCartItem(product, place));
    return;
  }

  dispatch(changeCartItemAmount(product, newAmount, place));
  dispatch(openTextNotification('product_amount_changed'));

  const cartItem = getState().cart.cart.find(i => i.ean === product.ean);

  if (cartItem && newAmount > cartItem.amount) {
    sendAddToCartGoal(place)();
  }
};

export default updateAmount;
