import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "src/redux/modals";
import { Modals } from "src/redux/modals/types";
import useLocalization from 'src/services/localization/useLocalization';
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { useToggles } from "src/utils/toggles/useToggles";
import { NotificationFeatureProps } from "../components/NotificationFeature/NotificationFeature";
import { sendFeatureNotificationButtonClick, sendFeatureNotificationClosed, sendFeatureNotificationShown } from "../helpers/analytics";
import { FeatureKey, getFeatureNotificationKey } from "../helpers/getFeatureNotificationKey";
import { useFeatureNotificationClosing } from "./useFeatureNotificationClosing";

interface NotificationConfig {
  featureKey: FeatureKey;
  title: string;
  description: string;
  iconSrc: string;
  iconAlt: string;
  modalName: keyof Modals;
}

export const useFeatureNotificationProps = () => {
  const localize = useLocalization();
  const dispatch = useDispatch();
  const { POSTOMAT_IS_AVAILABLE } = useToggles();
  const { imgRoot } = useAppConfig();

  const featureKey = getFeatureNotificationKey(POSTOMAT_IS_AVAILABLE);

  //feature should be different for different features
  const notificationConfig: NotificationConfig  = {
    featureKey,
    title: 'np_feature_title',
    description: 'np_feature_description',
    iconSrc: `${imgRoot}/nova_poshta/np.svg`,
    iconAlt: 'postomat',
    modalName: 'npPostomatModal',
  };

  if (!POSTOMAT_IS_AVAILABLE) {
    notificationConfig.title = 'new_feature_title';
    notificationConfig.description = 'recommendations_feature_description';
    notificationConfig.iconSrc = '/i/magic-wand.svg';
    notificationConfig.iconAlt = 'animation';
    notificationConfig.modalName = 'recommendationsModal';
  }

  const { isOpen, closeNotification } = useFeatureNotificationClosing();

  useEffect(() => {
    if (isOpen) {
      sendFeatureNotificationShown(notificationConfig.featureKey);
    }
  }, [isOpen, notificationConfig.featureKey]);

  const handleClick = () => {
    sendFeatureNotificationButtonClick(notificationConfig.featureKey);
    dispatch(openModal(notificationConfig.modalName));
    closeNotification();
  };

  const handleCloseClick = () => {
    sendFeatureNotificationClosed(notificationConfig.featureKey);
    closeNotification();
  };

  const notificationFeatureProps: NotificationFeatureProps = {
    title: localize(notificationConfig.title),
    description: localize(notificationConfig.description),
    onClick: handleClick,
    onClose: handleCloseClick,
    iconSrc: notificationConfig.iconSrc,
    iconAlt: notificationConfig.iconAlt,
    iconWithAnimation: !POSTOMAT_IS_AVAILABLE,
    featureKey: notificationConfig.featureKey,
  };

  return {
    isOpen,
    notificationFeatureProps,
  };
};
