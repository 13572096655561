import { NotificationOfGamification } from "src/modules/gamifications/FixedDeliveryDiscount";
import { useFeatureNotificationProps } from "../../hooks/useFeatureNotificationsProps";
import NotificationFeature from "../NotificationFeature/NotificationFeature";


const GamificationOrFeatureNotification = () => {
  const {
    isOpen,
    notificationFeatureProps,
  } = useFeatureNotificationProps();

  if (isOpen) {
    return <NotificationFeature {...notificationFeatureProps} />;
  }

  return <NotificationOfGamification />;
};

export default GamificationOrFeatureNotification;
