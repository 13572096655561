import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import useSWR from "swr";
import { OrderDetailInfo } from '../../../redux/apiTypes';
import getUserAgent from '../../../utils/api/getUserAgent';

export type UseNearestOrderResult = {
  nearestOrder: OrderDetailInfo,
  nearestOrderIsFetching: boolean,
}

export interface UseNearestOrderFunc {
  (userPhone: string): UseNearestOrderResult
}

export const irrelevantStatuses = ['delivered', 'cancelled'];

export const useNearestOrder: UseNearestOrderFunc = (userPhone) => {
  const { fetchConfig } = useAppConfig();

  const { data: nearestOrder, error } = useSWR(
    () => userPhone ? [fetchConfig.language, userPhone, 'nearest_order'] : null,
    getNearestOrder,
    {
      dedupingInterval: 15000,
      refreshInterval: 300000,
      revalidateOnFocus: false,
    },
  );

  const nearestOrderIsFetching = nearestOrder === undefined && error === undefined;

  return {
    nearestOrder,
    nearestOrderIsFetching,
  };

  async function getNearestOrder() {
    const { xChain, xVersion, apiRoot, language } = fetchConfig;

    const response = await fetch(
      `${apiRoot}/user/orders/nearest_active/`,
      {
        headers: {
          'Accept-Language': language,
          'x-chain': xChain,
          'x-version': xVersion,
          'User-Agent': getUserAgent(),
        },
        credentials: 'include',
      },
    );

    return response.status === 200 ? await response.json() : null;
  }
};
